<template>
    <div
        v-loading="isLoading"
        class="notifications-transfer-registration-form"
    >
        <el-form
            ref="form"
            :model="form"
            label-position="top"
        >
            <el-row :gutter="20">
                <el-col :span="16">
                    <el-form-item label="Текст сообщения">
                        <el-input
                            v-model="form.text"
                            type="textarea"
                            :autosize="{ minRows: 6, maxRows: 12}"
                        />
                    </el-form-item>
                    <p class="sms-counter">
                        Символов: {{ symbolsCount }}. СМС: {{ smsCount }}
                    </p>
                </el-col>
                <el-col :span="8">
                    <p class="notification-schedule">
                        {{ notificationSchedule }}
                    </p>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item label="Разослать">
                        <el-date-picker
                            v-model="form.send_at"
                            style="width: 100%;"
                            type="datetime"
                            format="d MMMM yyyy HH:mm"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Напомнить">
                        <el-date-picker
                            v-model="form.remind_at"
                            style="width: 100%;"
                            type="datetime"
                            format="d MMMM yyyy HH:mm"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <el-row>
            <el-button
                type="primary"
                :disabled="!form.editable"
                @click="save"
            >
                Сохранить
            </el-button>
        </el-row>
    </div>
</template>

<script>
import moment from 'moment';
import { Notifications } from '@/api';

export default {
    name: 'NotificationsTransferRegistrationForm',

    data() {
        return {
            isLoading: false,
            form: {
                text: ''
            }
        };
    },

    computed: {
        symbolsCount() {
            return this.form.text.length;
        },
        smsCount() {
            if (this.symbolsCount <= 70) {
                return 1;
            }

            return Math.ceil(this.symbolsCount / 67);
        },
        notificationSchedule() {
            let schedule = '';
            if (this.form.send_at) {
                schedule += `Рассылка запланирована на ${moment(this.form.send_at).format('D MMMM YYYY в HH:mm')}.`;
            }
            if (this.form.remind_at) {
                schedule += ` Напоминание будет ${moment(this.form.remind_at).format('D MMMM YYYY в HH:mm')}.`;
            }

            return schedule;
        }
    },

    async mounted() {
        this.isLoading = true;
        await Notifications.TransferRegistration.show()
            .then(response => {
                this.form = response;
            })
            .catch(e => {
                this.$message.error('Не удалось загрузить данные по СМС на трансфер');
            });
        this.isLoading = false;
    },

    methods: {
        async save() {
            const data = {
                text: this.form.text,
                send_at: this.form.send_at ? moment(this.form.send_at).format('YYYY-MM-DD HH:mm:ss') : null,
                remind_at: this.form.remind_at ? moment(this.form.remind_at).format('YYYY-MM-DD HH:mm:ss') : null
            };

            this.isLoading = true;
            await Notifications.TransferRegistration.update(data)
                .then(response => {
                    this.$message.success('Данные по СМС на трансфер обновлены');
                    this.form = response;
                })
                .catch(e => {
                    this.$message.error('Не удалось обновить данные по СМС на трансфер');
                });
            this.isLoading = false;
        }
    }
};
</script>

<style lang="scss">
    .notifications-transfer-registration-form {
        max-width: 710px;
    }

    .sms-counter {
        margin-top: -15px;
        text-align: right;
        font-size: 12px;
        color: gray;
    }

    .notification-schedule {
        margin-top: 47px;
        font-size: 12px;
        color: gray;
    }
</style>
