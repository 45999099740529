<template>
    <div class="event-add-view">
        <h1>
            Запись на трансфер
        </h1>

        <notifications-transfer-registration-form />
    </div>
</template>

<script>
import NotificationsTransferRegistrationForm from '../components/NotificationsTransferRegistrationForm';

export default {
    name: 'NotificationsTransferRegistrationView',

    components: {
        NotificationsTransferRegistrationForm
    }
};
</script>

<style lang="scss">

</style>
